<template>
  <div>
    <!--index.wxml-->
    <navbar url="/instrument" title="模拟打分结果"></navbar>
    <consult></consult>
    <div>
      <div class="bgImg">
        <img src="../assets/a.png" />
        <div class="title1">您的居住证积分模拟打分已完成</div>
        <div class="a">
          <div class="title2">模拟打分结果</div>
          <div class="btn">仅供参考</div>
        </div>
      </div>
      <div class="card">
        <div class="top">
          <img class="smail" src="../assets/smail.png" />
          <div class="scroll">{{ $route.query.grade }}分</div>
          <div class="txt">
            您的居住证积分
            <span style="color: red">{{ flag ? "已达标！" : "未达标" }}</span>
          </div>
        </div>
        <div class="bottom">
          <div class="item" v-if="flag">恭喜你,你将享受以下权益</div>
          <div class="item" v-if="!flag">很遗憾,你将错过以下权益</div>
          <div class="list">
            <div class="items">
              <img :src="flag == true ? n1 : n2" />
              子女义务教育阶段优先录取
            </div>
            <div class="items">
              <img :src="flag == true ? n1 : n2" />子女可在上海正常参加中、高考
            </div>
            <div class="items">
              <img :src="flag == true ? n1 : n2" />买房可免征收个人住房房产税
            </div>
            <div class="items">
              <img :src="flag == true ? n1 : n2" />子女可在沪购买居民医保
            </div>
          </div>
          <div class="getScro">各项得分</div>
          <div class="tableTitle">
            <div style="width: 50%; text-align: center">类别</div>
            <div style="width: 50%; text-align: center">分值</div>
          </div>
          <div class="tableList">
            <div
              class="tableTitle"
              style="background: #fff"
              v-for="(item, index) in scoreresultslist"
              :key="index"
            >
              <div class="left">{{ item.name }}</div>
              <div class="right">{{ item.grade }}</div>
            </div>
          </div>
          <div class="btns">
            {{ flag ? "点击右侧头像了解内容" : "点击右侧头像领取解决方案" }}
          </div>
          <div class="policy">政策依据：沪府发【2017】98号文</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      flag: false,
      n1: require("../assets/right.png"),
      n2: require("../assets/error.png"),
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
      scoreresultslist: (state) => state.scoreresultslist,
    }),
  },
  mounted() {
    if (this.$route.query.grade < 110) {
      this.flag = false;
    } else {
      this.flag = true;
    }
  },
};
</script>

<style lang='scss' scoped>
.bgImg {
  width: 100%;
  height: 5rem;
  background: linear-gradient(180deg, #12adfd 0%, #1899f5 100%);
  text-align: center;
  color: #fff;
  position: relative;
}

.bgImg img {
  width: 1.4rem;
  height: 1rem;
  margin-top: 0.5rem;
}

.a {
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
}

.title1 {
  padding-top: 0.1rem;
  font-size: 0.32rem;
}

.title2 {
  font-size: 0.3rem;
}

.card {
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
}

.btn {
  font-size: 0.25rem;
  margin-left: 0.1rem;
  width: 1.3rem;
  height: 0.4rem;
  line-height: 0.4rem;
  background: #fff;
  color: #12adfd;
  border-radius: 0.2rem;
  padding: 0 0.05rem;
}

.top {
  width: 6.5rem;
  height: 3rem;
  background: #fff;
  text-align: center;
  border: 1rpx solid #ddd;
}

.smail {
  width: 1.2rem;
  height: 1rem;
  margin: 0.2rem 0;
}

.scroll {
  font-size: 0.6rem;
}

.txt {
  padding: 0.1rem;
  color: #666;
  font-size: 0.3rem;
}

.bottom {
  /* height:500rpx; */
  width: 6.5rem;
  border: 0.01rem solid #ddd;
  background-color: #fff;
}

.list {
  margin-left: 1rem;
}

.item {
  font-size: 0.4rem;
  text-align: center;
  margin: 0.3rem 0;
}

.items {
  font-size: 0.3rem;
  margin: 0.2rem 0;
  color: #666;
}

.items img {
  width: 0.35rem;
  height: 0.3rem;
}

.getScro {
  text-align: center;
  margin: 0.6rem 0;
  font-size: 0.32rem;
}

.tableTitle {
  width: 90%;
  height: 0.7rem;
  background: #a0e1c0;
  margin: 0 auto;
  border-radius: 0.1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 0.3rem;
}

.tableItem {
  width: 90%;
  background: #f00;
  display: flex;
  margin: 0 auto;
  /* padding:0 130rpx 0  50rpx; */
  font-size: 0.29rem;
  margin: 0.2rem 0;
}

.left {
  width: 50%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.right {
  width: 50%;
  text-align: center;
}

.btns {
  width: 4.5rem;
  height: 0.7rem;
  line-height: 0.7rem;
  color: #fff;
  text-align: center;
  background: #3068f0;
  border-radius: 0.5rem;
  margin: 0 auto;
  margin-top: 1.3rem;
  font-size: 0.29rem;
}

.policy {
  width: 4rem;
  margin: 0.3rem auto;
  text-align: center;
  border-bottom: 0.01rem solid #ddd;

  font-size: 0.25rem;
}

.ganduo .imgs {
  border: 0.08rem solid #fff;
  box-sizing: border-box;
  width: 1.06rem;
  height: 1.06rem;
  margin-left: 0.4rem;
  border-radius: 50%;
  transform: translateY(0.3rem);
}

.ganduo {
  position: fixed;
  right: 0.1rem;
  bottom: 1rem;
}

.ganduo .zx {
  position: relative;
  z-index: 1000;
  margin-left: 30px;
  text-align: center;
  color: #fff;
  font-size: 0.24rem;
  width: 1.3rem;
  height: 0.47rem;
  line-height: 0.47rem;
  background: #335bff;
  border-radius: 0.25rem;
}

.ganduo img {
  margin-left: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  box-sizing: border-box;
}

.gonglang {
  /* position: fixed; */
  box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(0, 0, 0, 0.19);
  border-radius: 48px;
  /* right: 47rpx;
  top: 800rpx; */
  width: 0.9rem;
  height: 0.9rem;
}

.gonglang img {
  padding: 0.2rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.alert {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  background-color: rgb(0, 0, 0);
  filter: alpha(opacity=60);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.auth {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 3.4rem;
  border-radius: 0.2rem;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  flex-direction: column;
}

.confirm {
  border-top: 0.01rem solid rgb(221, 221, 221);
  text-align: center;
  height: 1rem;
  width: 100%;
  line-height: 1rem;
  font-size: 0.34rem;
  color: rgb(255, 145, 37);
  background: rgb(255, 255, 255) !important;
}

.apply {
  margin: 0rem auto;
  padding: 0.6rem 0.4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 0.34rem;
  color: rgb(34, 34, 34);
}

.apply .hh {
  font-size: 0.3rem;
}

.sq {
  margin-top: 0.5rem;
  font-size: 0.42rem;
  color: #fff;
}

.tac {
  text-align: center;
  color: #ccc;
}
</style>